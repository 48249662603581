import { Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTheme } from '../../ThemeContext';
import { AtiraError } from '../../components/AtiraError';
import { Button } from '../../components/Button';
import { CopyButton } from '../../components/CopyButton';
import { DeleteButton } from '../../components/DeleteButton';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { WarningModal } from '../../components/WarningModal';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { urlSliceSelectors } from '../../redux/url/url.selector';
import { shortenUrlActions } from '../../redux/url/url.slice';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { AtiraToast } from '../../utils/AtiraToast';
import { standardDate } from '../../utils/Date';

const URLContainer = styled(Flex)`
  width: 20%;
  justify-content: center;
`;

const TableHeader = styled(Text)`
  font-size: 1.25rem;
`;

export const UserURLs: React.FC = () => {
  const { t } = useTranslation();

  const [currentURLId, setCurrentURLId] = useState('');
  const [deleteFormModalVisibile, setDeleteFormModalVisibile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableEnableURLLoadingIndex, setDisableEnableURLLoadingIndex] =
    useState<number | undefined>(undefined);

  const { theme } = useTheme();

  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  )!;
  const userShortURLs = useAppSelector((s) =>
    urlSliceSelectors.selectShortURLs(s, loggedInUserId),
  );

  const dispatch = useAppDispatch();

  if (!userShortURLs.length) {
    return <AtiraError errorMessage={t('shorten_url.no_urls')} />;
  }

  const deleteURLWarning = (urlId: string) => {
    setCurrentURLId(urlId);
    setDeleteFormModalVisibile(true);
  };

  const deleteURL = async () => {
    try {
      setLoading(true);

      await dispatch(
        shortenUrlActions.deleteURL({
          urlId: currentURLId,
          userId: loggedInUserId,
        }),
      ).unwrap();

      setDeleteFormModalVisibile(false);

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();

      AtiraToast.success(t('shorten_url.delete.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
    } finally {
      setLoading(false);
      setCurrentURLId('');
    }
  };

  const toggleURLStatus = async (
    urlId: string,
    enabled: boolean,
    index: number,
  ) => {
    try {
      setDisableEnableURLLoadingIndex(index);

      if (enabled) {
        await dispatch(
          shortenUrlActions.disableUserURL({
            urlId,
            userId: loggedInUserId,
          }),
        ).unwrap();
        AtiraToast.success(t('url.disable.success'));
      } else {
        await dispatch(
          shortenUrlActions.enableUserURL({
            urlId,
            userId: loggedInUserId,
          }),
        ).unwrap();
        AtiraToast.success(t('url.enable.success'));
      }

      await dispatch(
        shortenUrlActions.getUserShortURLs({ userId: loggedInUserId }),
      ).unwrap();
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setDisableEnableURLLoadingIndex(undefined);
    }
  };

  return (
    <Flex width={'100%'} height={'100%'} flexDirection="column">
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding="s"
        >
          <Flex>
            <Text>{t('shorten_url.your_short_urls')}</Text>
          </Flex>
          <Flex
            width="15rem"
            gap="m"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Text>
              {t('common.total')} {userShortURLs.length}
            </Text>
          </Flex>
        </Flex>

        <Flex
          justifyContent="space-between"
          width="100%"
          backgroundColor={theme.lightergray}
          style={{ padding: '1rem 0' }}
        >
          <URLContainer>
            <TableHeader>{t('shorten_url.short_url')}</TableHeader>
          </URLContainer>
          <URLContainer>
            <TableHeader>{t('shorten_url.original_url')}</TableHeader>
          </URLContainer>
          <URLContainer>
            <TableHeader>{t('common.status')}</TableHeader>
          </URLContainer>
          <URLContainer>
            <TableHeader>{t('common.click_count')}</TableHeader>
          </URLContainer>
          <URLContainer>
            <TableHeader>{t('shorten_url.create_date')}</TableHeader>
          </URLContainer>
          <URLContainer>
            <TableHeader>{t('common.actions')}</TableHeader>
          </URLContainer>
        </Flex>
        <div
          style={{
            overflow: 'scroll',
            width: '100%',
            height: '20rem',
            margin: '1rem 0',
          }}
        >
          {userShortURLs.map((url, index) => (
            <Flex justifyContent="center" alignItems="center" key={url._id}>
              <Flex width={'20%'} alignItems="center" gap="s" marginLeft="m">
                <Text color={url.enabled ? 'black' : 'gray'}>
                  {url.shortURL}
                </Text>
              </Flex>
              <URLContainer>
                <Text color={url.enabled ? 'black' : 'gray'}>
                  {url.originalURL}
                </Text>
              </URLContainer>
              <URLContainer alignItems="center" opacity={url.enabled ? 1 : 0.5}>
                <Tooltip
                  title={t(
                    `shorten_url.${url.enabled ? 'enabled' : 'disabled'}.message`,
                  )}
                >
                  <Button
                    loading={disableEnableURLLoadingIndex === index}
                    onClick={() => toggleURLStatus(url._id, url.enabled, index)}
                  >
                    {url.enabled ? t('common.enabled') : t('common.disabled')}
                  </Button>
                </Tooltip>
              </URLContainer>
              <URLContainer>
                <Text>{url.visits}</Text>
              </URLContainer>
              <URLContainer>
                <Text>{standardDate(url.createdAt)}</Text>
              </URLContainer>
              <Flex
                gap="s"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                width={'20%'}
              >
                <CopyButton copy={url.shortURL!} />
                <DeleteButton onDelete={() => deleteURLWarning(url._id)} />
              </Flex>
            </Flex>
          ))}
        </div>
      </Flex>

      <WarningModal
        onClose={() => setDeleteFormModalVisibile(false)}
        open={deleteFormModalVisibile}
        title={t('shorten_url.delete_modal.title')}
        description={t('shorten_url.delete_modal.description')}
        onConfirm={deleteURL}
        loading={loading}
      />

      <Text align="center">{t('shorten_url.my_urls.footer_paragraph')}</Text>
    </Flex>
  );
};
