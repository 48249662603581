import { AtiraRoute } from '../AtiraRoute';
import { QRGenerator } from '../QR-generator/QRGenerator';
import { QRRoute } from '../QR-generator/QRRoute';
import { AnalyticsRoute } from '../analytics/AnalyticsRoute';
import { Dashboard } from '../dashbord/Dashbord';
import { Nerve } from '../nerve/Nerve';
import { NerveRoute } from '../nerve/NerveRoute';
import { URLRoute } from '../url/URLRoute';
import { URLShortner } from '../url/URLShortner';

export const PrivateRouter = [
  {
    path: '/dashboard',
    element: <AtiraRoute component={<Dashboard />} />,
  },
  {
    path: '/my-urls',
    element: <AtiraRoute component={<URLRoute />} />,
  },
  {
    path: '/my-qrs',
    element: <AtiraRoute component={<QRRoute />} />,
  },
  {
    path: '/my-link',
    element: <AtiraRoute component={<NerveRoute />} />,
  },
  {
    path: '/my-link/create',
    element: <AtiraRoute component={<Nerve />} />,
  },
  {
    path: '/my-qrs/create',
    element: <AtiraRoute component={<QRGenerator />} />,
  },
  {
    path: '/my-urls/create',
    element: <AtiraRoute component={<URLShortner />} />,
  },
  {
    path: '/analytics',
    element: <AtiraRoute component={<AnalyticsRoute />} />,
  },
];
