import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import upperCase from 'lodash/upperCase';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Button } from '../../components/Button';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import i18n, { AppLangs } from '../../i18n';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { AtiraNerveSocialHandlers } from '../../model/nerve/types/NerveSocialMediaHandlers.enum';
import { Spacing } from '../../theme/Spacing';
import { AtiraToast } from '../../utils/AtiraToast';

const socialMediaIcons = {
  whatsapp: faWhatsapp,
  instagram: faInstagram,
  twitter: faXTwitter,
  facebook: faFacebook,
  youtube: faYoutube,
};

const placeholders: Record<AtiraNerveSocialHandlers, string> = {
  whatsapp: 'https://wa.me/905...',
  instagram: 'https://www.instagram.com/...',
  twitter: 'https://www.twitter.com/...',
  facebook: 'https://www.facebook.com/...',
  youtube: 'https://www.youtube.com/...',
  telegram: 'https://www.tiktok.com/...',
  tiktok: 'https://www.telegram.com/...',
};

const StyledButton = styled(Button)`
  width: 7rem;
  height: 3rem;
  font-size: 1rem;
`;

const StyleInput = styled(Input)`
  width: 20rem;
  height: 3rem;
  padding: 0 0.4rem;
  border: 2px solid ${(props) => props.theme.black};
  font-size: 1rem;
`;

const LeftSideWrapper = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: 50%;
  gap: ${Spacing.m};
  border-right: ${({ theme }) =>
    i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
  border-left: ${({ theme }) =>
    i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};

  padding-right: ${i18n.language === AppLangs.AR ? '0' : Spacing.m};
  padding-left: ${i18n.language === AppLangs.AR ? Spacing.m : '0'};
`;

export const NerveSocial: React.FC = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext<CreateNerveDto>();
  const { remove, append } = useFieldArray({ name: 'socials' });

  const socials = watch('socials') || [];
  const mapAbleSocials = socials.flatMap((item) => Object.entries(item));
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] =
    useState<AtiraNerveSocialHandlers | null>(null);

  const socialMediaOptions = [
    { value: 'whatsapp', label: t('common.whatsapp') },
    { value: 'instagram', label: t('common.instagram') },
    { value: 'twitter', label: t('common.twitter') },
    { value: 'facebook', label: t('common.facebook') },
    { value: 'youtube', label: t('common.youtube') },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onAddOption = () => {
    if (!selectedOption) {
      return AtiraToast.error('You must select an option');
    }

    if (!inputValue) {
      return AtiraToast.error('You must provide an URL');
    }

    append({ [selectedOption]: inputValue });
    setSelectedOption(null);
    setInputValue('');
  };

  const getSocialMediaLabel = (value: AtiraNerveSocialHandlers): string => {
    return (
      socialMediaOptions.find((option) => option.value === value)?.label || ''
    );
  };

  const getPlaceholder = (value: AtiraNerveSocialHandlers): string => {
    return placeholders[value] || '';
  };

  return (
    <Flex width={'100%'} gap="l">
      <LeftSideWrapper>
        <Flex gap="l" flexDirection="column" width={'100%'}>
          <DropDown
            title={t('nerves.create.steps.socials.dropdown.title')}
            value={{
              label:
                t(`common.${selectedOption?.toLowerCase()}`) ||
                t('common.dropdown.select'),
              value: selectedOption,
            }}
            options={socialMediaOptions.filter(
              (s) =>
                !socials.find((social) =>
                  Object.keys(social).includes(s.value),
                ),
            )}
            onChange={(v) => setSelectedOption(v as AtiraNerveSocialHandlers)}
          />

          <StyleInput
            id="url"
            title={t('common.enter_your_url')}
            value={inputValue}
            placeholder={selectedOption ? getPlaceholder(selectedOption) : ''}
            onChange={handleInputChange}
          />
        </Flex>

        <StyledButton onClick={onAddOption} disabled={!selectedOption}>
          {t('common.add')} +
        </StyledButton>
      </LeftSideWrapper>

      <Flex flexDirection="column" alignItems="center" gap="m" flexGrow={1}>
        {mapAbleSocials.map(([key], index) => (
          <Flex
            key={key}
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
            gap="s"
          >
            <Flex alignItems="center" gap="s">
              <AtiraIcon
                icon={socialMediaIcons[key as keyof typeof socialMediaIcons]}
                size="3x"
              />
              <Text>
                {getSocialMediaLabel(key as AtiraNerveSocialHandlers)}
              </Text>
            </Flex>

            <Button onClick={() => remove(index)} title={t('common.delete')} />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
