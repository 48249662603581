import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AtiraError } from '../../components/AtiraError';
import { AtiraStepper } from '../../components/AtiraStepper';
import { Flex } from '../../components/Flex';
import { StepperStep } from '../../components/StepperStep';
import i18n from '../../i18n';
import { CreateNerveDto } from '../../model/nerve/dto/CreateNerveDto';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { nerveSliceSelectors } from '../../redux/nerve/nerve.selector';
import { useAppSelector } from '../../redux/store';
import { userSliceSelectors } from '../../redux/user/user.selector';
import { NerveBasicInfo } from './NerveBasicInfo';
import { NerveCustomLinks } from './NerveCustomLinks';
import { NerveID } from './NerveID';
import { NerveSocial } from './NerveSocial';

export const Nerve: React.FC = () => {
  const { t } = useTranslation();

  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId);
  const nerves = useAppSelector(nerveSliceSelectors.selectUserNerves);
  const loggedInUser = useAppSelector(userSliceSelectors.selectLoggedInUser);

  const isProUser = loggedInUser?.kind === UserKind.PAID;

  const formMethods = useForm<CreateNerveDto & { nerveName: string }>({
    defaultValues: {
      name: loggedInUser!.name || '',
      phone: loggedInUser!.phone || '',
      bio: '',
      map: '',
      email: loggedInUser!.email || '',
      image: '',
      socials: [],
      nerveName: '',
      userId,
      customLinks: [],
    },
  });

  if (nerves.length >= 2) {
    return (
      <Flex flex={1} flexDirection="column">
        <AtiraError
          errorMessage={t('nerves.create.reached_max_count_message')}
        />
      </Flex>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <Flex width={'100%'} flexDirection="column">
        <AtiraStepper
          components={[
            <StepperStep
              title={t('nerves.create.info_step.title')}
              children={<NerveBasicInfo />}
            />,
            <StepperStep
              title={t('nerves.create.socials_step.title')}
              children={<NerveSocial />}
            />,

            ...(isProUser
              ? [
                  <StepperStep
                    title={t('nerves.custom_link.title')}
                    children={<NerveCustomLinks />}
                  />,
                ]
              : []),
            <StepperStep
              title={t('nerves.create.steps.nerve_name.title')}
              children={<NerveID />}
            />,
          ]}
          items={[
            { title: t('common.links') },
            { title: t('nerves.create.steps.socials') },
            ...(isProUser
              ? [{ title: t('nerves.create.steps.custom_link') }]
              : []),
            { title: t('nerves.create.steps.nerve_name') },
          ]}
        />
      </Flex>
    </FormProvider>
  );
};
