import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraColumn } from '../../components/AtiraColumn';
import { CopyButton } from '../../components/CopyButton';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { PageHeader } from '../../components/PageHeader';
import { Text } from '../../components/Text';
import { UserHeader } from '../../components/UserHeader';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';

const SectionWrapper = styled(Flex)`
  flex-direction: column;
  gap: ${Spacing.s};
`;

const Section = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  flex-direction: column;
  gap: ${Spacing.m};
`;

const InputSection = styled(Flex)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  align-items: center;
  width: 25rem;
  gap: ${Spacing.m};
`;

const StyledButton = styled(CopyButton)`
  background-color: ${({ theme }) => theme.white};
  border-radius: ${Rounded.lg};
  padding: ${Spacing.m};
  margin: 0rem;
`;

const SectionTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1.3rem;
`;

const SectionSecondTitle = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
  font-weight: bold;
  font-size: 1rem;
`;

const StyledNumber = styled(Text)`
  color: ${({ theme }) => theme.darkerSub};
`;

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Flex gap="m" margin="m" flexDirection="column" flex={1}>
      <PageHeader
        leftComponent={
          <Flex gap="m">
            <InputSection>
              <Text color="main" fontSize="l" fontWeight={'bolder'} cutText>
                {t('common.your_link')}:
              </Text>
              <Input />
            </InputSection>
            <StyledButton copy="" />
          </Flex>
        }
        rightComponent={
          <Flex justifyContent="end">
            <UserHeader />
          </Flex>
        }
      />

      <Flex gap="m" flex={1}>
        <SectionWrapper width={'100%'}>
          <Section>
            <SectionTitle>{t('common.activity')}</SectionTitle>

            <Row justify={'space-between'}>
              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>100</Text>
                  <StyledNumber>{t('common.views')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>70</Text>
                  <StyledNumber>{t('common.unique_views')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>47</Text>
                  <StyledNumber>{t('common.clicks')}</StyledNumber>
                  <Text>/</Text>
                  <Text fontWeight={'bolder'}>42</Text>
                  <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                </Flex>
              </AtiraColumn>

              <AtiraColumn xl={4}>
                <Flex gap="s">
                  <Text fontWeight={'bolder'}>47</Text>
                  <Text>%</Text>
                  <StyledNumber fontWeight={'bolder'}>
                    {t('common.ctr')}
                  </StyledNumber>
                </Flex>
              </AtiraColumn>
            </Row>
          </Section>

          <Section>
            <SectionTitle>{t('common.device')}</SectionTitle>
            <Flex flexDirection="column" gap="m">
              <SectionSecondTitle>{t('common.mobil')}</SectionSecondTitle>

              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
              <SectionSecondTitle>{t('common.desktop')}</SectionSecondTitle>
              <Row justify={'space-between'}>
                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>100</Text>
                    <StyledNumber>{t('common.views')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>70</Text>
                    <StyledNumber>{t('common.unique_views')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <StyledNumber>{t('common.clicks')}</StyledNumber>
                    <Text>/</Text>
                    <Text fontWeight={'bolder'}>42</Text>
                    <StyledNumber>{t('common.unique_clicks')}</StyledNumber>
                  </Flex>
                </AtiraColumn>

                <AtiraColumn xl={4}>
                  <Flex gap="s">
                    <Text fontWeight={'bolder'}>47</Text>
                    <Text>%</Text>
                    <StyledNumber fontWeight={'bolder'}>
                      {t('common.ctr')}
                    </StyledNumber>
                  </Flex>
                </AtiraColumn>
              </Row>
            </Flex>
          </Section>
        </SectionWrapper>

        <SectionWrapper width={'25%'} gap="l">
          <Section height={'100%'}>PHONE</Section>
        </SectionWrapper>
      </Flex>
    </Flex>
  );
};
