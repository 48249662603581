import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styled from 'styled-components';

import i18n, { AppLangs } from '../i18n';
import { Rounded } from '../theme/Rounded';
import { Spacing } from '../theme/Spacing';
import { Flex } from './Flex';

const StyledTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${Spacing.m};
`;

const StyledTabList = styled(TabList)`
  font-size: 1.5rem;
  background-color: ${({ theme }) => theme.white};
  width: fit-content;
  list-style-type: none;
  border-radius: ${Rounded.lg};
  overflow: hidden;
  padding: ${Spacing.s};
`;

const StyledTab = styled(Tab)<{
  activeTab: number;
  index: number;
}>`
  color: ${({ theme, activeTab, index }) =>
    activeTab === index ? theme.main : theme.black};
  cursor: pointer;
  font-size: 1.4rem;
  outline: none;
  border-radius: 0;
  display: inline;
  padding: 0 ${Spacing.s};

  &:not(:last-child) {
    border-right: ${({ theme }) =>
      i18n.language !== AppLangs.AR ? `1px solid ${theme.black}` : '0'};

    border-left: ${({ theme }) =>
      i18n.language === AppLangs.AR ? `1px solid ${theme.black}` : '0'};
  }
`;

interface AtiraTabsProps {
  tabs: { tabTitle: string; tabPanel: React.ReactNode; showTab?: boolean }[];
  onChange?: (index: number) => void;
  sideComponent?: React.ReactNode;
  middleComponent?: React.ReactNode;
  activeTab?: number;
}

export const AtiraTabs: React.FC<AtiraTabsProps> = ({
  tabs,
  onChange,
  sideComponent,
  middleComponent,
  activeTab = 0,
}) => {
  const location = useLocation();
  const { tabIndex } = location.state || {};

  const [localActiveTab, setLocalActiveTab] = useState(
    tabIndex || activeTab || 0,
  );

  const defaultTabs = tabs
    .map((tab) => ({
      ...tab,
      showTab: tab.showTab !== false,
    }))
    .filter((tab) => tab.showTab);

  useEffect(() => {
    setLocalActiveTab(activeTab || 0);
  }, [activeTab]);

  return (
    <StyledTabs selectedIndex={localActiveTab}>
      <Flex justifyContent="space-between" alignItems="center">
        <StyledTabList>
          {defaultTabs.map((tab, index) => (
            <StyledTab
              activeTab={localActiveTab}
              index={index}
              key={`${tab.tabTitle}-${index}`}
              onClick={() => setLocalActiveTab(index)}
            >
              {tab.tabTitle}
            </StyledTab>
          ))}
        </StyledTabList>

        {localActiveTab === 0 && middleComponent}

        {sideComponent || null}
      </Flex>

      {tabs.map((tab, index) => (
        <TabPanel
          key={`${tab.tabTitle}-${index}`}
          style={{
            width: '100%',
            marginTop: Spacing.m,
          }}
        >
          {tab.tabPanel}
        </TabPanel>
      ))}
    </StyledTabs>
  );
};
